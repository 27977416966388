import React, { useContext, useState } from "react";

export interface Branding {
  logo: string;
  gesellschaftsName: string;
  impressumLink: string;
  datenschutzLink: string;
  kontaktEmail: string;
  kontaktTelefon: string;
}

export interface BrandingContextProps {
  branding: Branding | null;
  switchBranding: (newBranding: Branding) => unknown;
}

export const BrandingContext = React.createContext<BrandingContextProps>({
  branding: null,
  switchBranding: (_newBranding: Branding) => null,
});

export const BrandingContextProvider: React.FC = (props) => {
  const [branding, setBranding] = useState<Branding | null>(null);

  const switchBranding = (newBranding: Branding) => {
    setBranding(newBranding);
  };

  return (
    <BrandingContext.Provider
      value={{
        branding: branding,
        switchBranding: switchBranding,
      }}
    >
      {props.children}
    </BrandingContext.Provider>
  );
};

export const useBranding = () => {
  const ctx = useContext(BrandingContext);
  if (ctx === undefined) {
    throw new Error(
      "useBranding muss in einem BrandingContext Provider genutzt werden"
    );
  }
  return ctx;
};
