export interface AuthData {
  auftragsnummer: string;
  kundennummer: string;
  token: string;
  vertragsdaten: Vertragsdaten;
}

export interface Vertragsdaten {
  objektAdresseStrasse: string;
  objektAdresseHausnummer: string;
  objektAdresseAdresszusatz: string;
  objektAdressePlz: string;
  objektAdresseOrt: string;

  // Gesellschaft
  objektGesellschaftName: string;
  objektGesellschaftAnzeigeName: string;
  objektGesellschaftAdresseStrasse: string;
  objektGesellschaftAdresseHausnummer: string;
  objektGesellschaftAdressePlz: string;
  objektGesellschaftAdresseOrt: string;

  // SapAuftragsnummer + MassnahmenNummer === Auftragsnummer
  vertragspartnerVorname: string;
  vertragspartnerNachname: string;
  vertragspartnerFirma: string;
  vertragspartnerEmail: string;

  antwort: number;
  antwortAm: string;
}

export interface Zustimmung {
  istZustimmung: string;
  widerrufGelesen: string;
  emailGewuenscht: boolean;
  email: string;
}

export const ZustimmungOptions = {
  Unbekannt: "unbekannt",
  Zustimmung: "zustimmung",
  Widerruf: "widerruf",
  WiderrufGelesen: "widerrufGelesen",
};

export enum ZustimmungOptionsEnum {
  keineAntwort,
  zugestimmt,
  widerruf,
}

export interface KundenLogin {
  auftragsnummer: string;
  kundennummer: string;
}

export interface ZustimmungAntwort {
  zustimmung: boolean;
  emailGewuenscht: boolean;
  emailAdresse: string;
}

export const SESSIONITEM_VERTRAGSDATEN = "Vertragsdaten";
