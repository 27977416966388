import React, { PropsWithChildren } from "react";

//TODO: fix-me!
// import Zahnraeder from "assets/zahnraeder.svg";
import { Modal } from "react-bootstrap";

type sizeType = "s" | "m" | "l";
type Props = PropsWithChildren<{
  /**
   * should the loader be shown
   *
   * @type {boolean}
   */
  show: boolean;
  /**
   * size of the animation, default "m". Valid sizes are:
   *  s = 16px,
   *  m = 32px,
   *  l = 48px
   *
   * @type {sizeType}
   */
  size?: sizeType;
}>;

const getSize = (size: sizeType) => {
  return size === "s" ? 16 : size === "m" ? 32 : 48;
};

export const Loader: React.FC<Props> = ({ show, children, size: propsize = "m" }: Props) => {
  if (!show) return null;
  const size = getSize(propsize);

  return (
    <>
      <object type="image/svg+xml" height={size} width={size} data="/zahnraeder.svg">
        svg-animation
      </object>
      <span hidden={!children} className="ml-2">
        {children}
      </span>
    </>
  );
};

export const ModalLoader: React.FC<Props> = ({ show, children, size: propsize = "m" }: Props) => {
  if (!show) return null;
  const size = getSize(propsize);

  return (
    <Modal show={show} centered backdrop="static">
      <Modal.Body className="d-flex align-items-center">
        <object type="image/svg+xml" height={size} width={size} data="/zahnraeder.svg">
          svg-animation
        </object>
        <span className="ml-2">{children}</span>
      </Modal.Body>
    </Modal>
  );
};
