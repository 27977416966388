import * as Yup from "yup";
import { Shape } from "../common/Validation";

import { KundenLogin, Zustimmung, ZustimmungOptions } from "./Types";

export const KundenLoginSchema = Yup.object<Shape<KundenLogin>>({
  auftragsnummer: Yup.string()
    .ensure()
    .required("Bitte geben Sie eine Auftragsnummer an"),
  kundennummer: Yup.string()
    .ensure()
    .required("Bitte geben Sie eine Kundennummer an"),
});

export const ZustimmungSchema = Yup.object<Shape<Zustimmung>>({
  istZustimmung: Yup.string()
    .ensure()
    .default(ZustimmungOptions.Unbekannt)
    .oneOf(
      [ZustimmungOptions.Zustimmung, ZustimmungOptions.Widerruf],
      "Bitte stimmen Sie zu oder lehnen Sie ab."
    ),
  widerrufGelesen: Yup.string()
    .ensure()
    .default(ZustimmungOptions.Unbekannt)
    .oneOf(
      [ZustimmungOptions.WiderrufGelesen],
      "Bitte bestätigen Sie, dass Sie die Widerrufsbelehrung gelesen haben."
    ),
  email: Yup.string().when("emailGewuenscht", {
    is: true,
    then: Yup.string()
      .ensure()
      .email("Bitte geben Sie eine gültige Emailadresse an")
      .required("Bitte geben Sie eine Emailadresse an"),
  }),
  emailGewuenscht: Yup.bool(),
});
