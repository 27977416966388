import React from "react";
import { Col, Container, Jumbotron, Row } from "react-bootstrap";
import { backgroundStyles } from "../styles/splashScreenStyles";
import { useBranding } from "./BrandingContext";
import { ZustimmungAntwort } from "./Types";

const Bestaetigung: React.VFC<ZustimmungAntwort> = ({
  zustimmung,
  emailAdresse,
  emailGewuenscht,
}) => {
  const { branding } = useBranding();
  return (
    <>
      <div style={backgroundStyles} className="h-100">
        <Container>
          <Row style={{ marginTop: "5rem" }}>
            <Col md={8} lg={8}>
              <Jumbotron>
                <h1 className="display-4">Vielen Dank für Ihre Antwort</h1>
                {emailGewuenscht && (
                  <p>
                    Wir haben Ihre{!zustimmung ? "n" : ""}{" "}
                    <strong>{zustimmung ? "Zustimmung" : "Widerruf"}</strong>{" "}
                    zur Gestattungsvereinbarung erhalten und senden Ihnen
                    umgehend eine Bestätigungsmail an {emailAdresse}.
                  </p>
                )}
                <p>
                  Sollten Sie noch zur Gestattungsvereinbarung Fragen haben,
                  wenden Sie sich per E-Mail an:{" "}
                  <a href={`mailto:${branding?.kontaktEmail}`}>
                    {branding?.kontaktEmail}
                  </a>{" "}
                  oder telefonisch an{" "}
                  <a href={`tel:${branding?.kontaktTelefon}`}>
                    {branding?.kontaktTelefon}
                  </a>
                </p>
                <p>Mit freundlichen Grüßen</p>
                <p>Ihr Team blau-grün</p>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Bestaetigung;
