import React, { createContext, ReactElement, ReactNode, useMemo } from "react";
import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { log } from "../common/Logger";
import { AuthData } from "../domain/Types";

interface Props {
  children: ReactNode;
}

interface ApiContextProps {
  api: AxiosInstance;
}

const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
};

export const ApiContext = createContext<ApiContextProps>({
  api: axios.create(),
});

export function ApiContextProvider({ children }: Props): ReactElement {
  //const authService = useContext(AuthContext);

  const api = useMemo(() => {
    const axiosInstance = axios.create(axiosRequestConfiguration);
    log.debug("initializing new axios api-instance");

    axiosInstance.interceptors.request.use(
      async (config) => {
        try {
          log.debug("getting access token from auth-provider via api-context for request %s", config.url);
          let token = "";
          if (sessionStorage.Vertragsdaten) {
            const vertragsdaten: AuthData = JSON.parse(sessionStorage.Vertragsdaten);
            token = vertragsdaten.token;
          }
          config.headers.Authorization = `Bearer ${token}`;
          config.withCredentials = false;
          log.debug("added access token to request-header via api-context for request %s", config.url);
          return Promise.resolve(config);
        } catch (error) {
          log.error({ obj: error }, "oops during axios request interceptor for bearer tokens");
          return Promise.reject(error);
        }
      },
      (error) => {
        log.error("error while retrieving access token via api-context");
        Promise.reject(error);
      }
    );

    // Trace-Parent für Logging mitgeben
    // TODO: sollte irgendwie pro "Aktion" generiert werden
    // axiosInstance.interceptors.request.use((value) => {
    //   value.headers.TraceParent = "12345-12345-12345";
    //   return value;
    // });

    return axiosInstance;
  }, []);

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
}
