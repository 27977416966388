import React, { useEffect } from "react";
import { backgroundStyles } from "../styles/splashScreenStyles";
import { Container } from "react-bootstrap";
import Anmeldung from "./Anmeldung";
import { useBranding } from "./BrandingContext";
import branding from "../data/branding.json";
import { log } from "../common/Logger";
import GwLogo from "../assets/gw_logo.png";

// TODO: defaults für Branding
const defaults = {
  logoUrl: GwLogo,
  impressumLink: "",
  datenschutzLink: "",
};

const Main: React.FC = () => {
  const { switchBranding } = useBranding();

  useEffect(() => {
    const hostToCheck = window.location.hostname;
    log.debug(`host to check: ${hostToCheck}`);

    if (branding?.length) {
      const foundBranding = branding.filter(
        (b) =>
          process.env.REACT_APP_DEFAULT_BRANDING === "true" ||
          (b.hostname.indexOf(hostToCheck) > -1 && !b.hostname)
      );
      switchBranding({
        datenschutzLink:
          foundBranding[0]?.datenschutzLink || defaults.datenschutzLink,
        impressumLink:
          foundBranding[0]?.impressumLink || defaults.impressumLink,
        kontaktEmail: foundBranding[0]?.kontaktEmail || "",
        kontaktTelefon: foundBranding[0]?.kontaktTelefon || "",
        logo: `./logos/${foundBranding[0]?.logo}` || defaults.logoUrl,
        gesellschaftsName: foundBranding[0]?.gesellschaftsName || "",
      });
    }
  }, []);

  return (
    <div style={backgroundStyles} className="h-100">
      <Container>
        <Anmeldung />
      </Container>
    </div>
  );
};

export default Main;
