import { createLogger, stdSerializers, ConsoleFormattedStream } from "browser-bunyan";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const allStreams: any[] = [];

const conStream = {
  level: process.env.REACT_APP_LOGLEVEL,
  stream: new ConsoleFormattedStream(),
};
allStreams.push(conStream);

export const log = createLogger({
  name: "Gestattungsvereinbarung Glasfaser",
  level: process.env.REACT_APP_LOGLEVEL,
  streams: allStreams,
  serializers: stdSerializers,
  src: false,
});

log.info("initialized logger");
