import React from "react";
import { Alert, AlertProps } from "react-bootstrap";

import styles from "./AlertTriangle.module.scss";

const Index: React.FC<AlertProps> = (props: AlertProps) => (
  <Alert {...props}>
    <div className={styles.Triangle + " border-" + props.variant} />
    {props.children}
  </Alert>
);

export default Index;
