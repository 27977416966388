import React from "react";
import Layout from "./components/Layout";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Main from "./domain/Main";
import "@gelsenwasser/react/lib/Styles/gwag-theme.scss";
import { ApiContextProvider } from "./services/ApiContext";

import { NotificationProvider } from "@gelsenwasser/react";
import Gestattungsvertrag from "./domain/Zustimmung";
import { BrandingContextProvider } from "./domain/BrandingContext";

export default class App extends React.Component {
  render(): JSX.Element {
    return (
      <ApiContextProvider>
        <NotificationProvider>
          <BrandingContextProvider>
            <BrowserRouter>
              <Layout>
                <Switch>
                  <Route path="/gestattung" component={Gestattungsvertrag} />
                  <Route path="/" component={Main} />
                </Switch>
              </Layout>
            </BrowserRouter>
          </BrandingContextProvider>
        </NotificationProvider>
      </ApiContextProvider>
    );
  }
}
