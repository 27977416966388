import React, { ReactNode } from "react";

import { Footer, FooterListClassname, Header } from "@gelsenwasser/react";

import GwLogo from "../assets/gw_logo.png";
import { useBranding } from "../domain/BrandingContext";

interface Props {
  children: ReactNode;
}

export const Layout: React.FC<Props> = ({ children }: Props) => {
  const { branding } = useBranding();
  return (
    <>
      <Header
        noWhiteBackground
        logoSrc={branding?.logo ?? GwLogo}
        title={
          <>
            <strong>Gestattungs</strong>
            <span className="font-weight-light">
              vereinbarung kombinierter Hausanschluss
            </span>
          </>
        }
      />

      <main className="d-flex flex-column flex-fill outermain bg-gw-light">
        {children}
      </main>

      <Footer
        year={process.env.REACT_APP_YEAR}
        version={process.env.REACT_APP_VERSION}
      >
        <ul className={FooterListClassname}>
          <li>
            <a href={branding?.impressumLink} target="_blank" rel="noreferrer">
              Impressum
            </a>
          </li>
          <li>
            <a href={branding?.datenschutzLink} target="_blank" rel="noreferrer">
              Datenschutz
            </a>
          </li>
        </ul>
      </Footer>
    </>
  );
};

export default Layout;
