import {
  FormikControl,
  NotificationType,
  useNotification,
} from "@gelsenwasser/react";
import { Formik, Form as FormikForm } from "formik";
import React, { useContext, useEffect } from "react";
import { Row, Col, Jumbotron, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { parse } from "qs";
import { log } from "../common/Logger";
import { ApiContext } from "../services/ApiContext";
import { AuthData, KundenLogin, SESSIONITEM_VERTRAGSDATEN } from "./Types";
import { KundenLoginSchema } from "./Validation";
import { useBranding } from "./BrandingContext";

const Anmeldung: React.VFC = () => {
  // hooks
  const { api } = useContext(ApiContext);
  const history = useHistory();
  const location = useLocation();
  const { addNotification } = useNotification();
  const { branding } = useBranding();
  // states
  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    if (!location.search) return;

    const parsed = parse(location.search, { ignoreQueryPrefix: true });
    if (parsed.knr && parsed.anr) {
      const loginData = {
        auftragsnummer: parsed.anr.toString(),
        kundennummer: parsed.knr.toString(),
      };
      log.debug(
        {
          obj: loginData,
        },
        "Parsed logindata"
      );
      handleLogin(loginData, null);
    }
  }, []);

  // daten
  // handler
  const handleLogin = (kundenLogin: KundenLogin, actions: any) => {
    api
      .post<AuthData>("login", kundenLogin)
      .then((res) => {
        log.trace(
          {
            obj: res.data,
          },
          `got Authdata for auftragsnummer ${res.data.auftragsnummer} and kundennummer ${res.data.kundennummer}`
        );
        sessionStorage.setItem(
          SESSIONITEM_VERTRAGSDATEN,
          JSON.stringify(res.data)
        );
        history.push("/gestattung");
      })
      .catch((err) => {
        actions?.setSubmitting(false);
        if (err.response.status === 403) {
          if (err.response.data.detail === "ConsentAlreadyGiven") {
            addNotification(
              "Sie haben bereits eine Zustimmung oder einen Widerspruch abgegeben.",
              "Fehler",
              NotificationType.Alert
            );
            log.warn("ConsentAlreadyGiven");
          } else {
            addNotification(
              "Die Kombination aus Kundennummer und Auftragsnummer stimmen nicht überein.",
              "Fehler",
              NotificationType.Alert
            );
            log.warn(`Unauthorized`);
          }
        }
        if (err.response.status === 410) {
          addNotification(
            `Ihr Anschluss ist schon in Bearbeitung, eine Zustimmung ist zu diesem Zeitpunkt nicht mehr möglich. Wenden Sie sich bei Fragen per E-Mail an: ${branding?.kontaktEmail} oder telefonisch an ${branding?.kontaktTelefon}`,
            "Fehler",
            NotificationType.Alert
          );
          log.warn(`Invalid`);
        }
      });
  };

  const emptyLogin = KundenLoginSchema.getDefault();

  return (
    <>
      <Row style={{ marginTop: "5rem" }}>
        <Col md={8} lg={8}>
          <Jumbotron>
            <h1 className="display-4">
              Herzlich Willkommen bei <strong>Gestattungs</strong>
              &shy;vereinbarung Glasfaser
            </h1>
            <p>
              Willkommen auf dem{" "}
              <strong>Gestattungsvereinbarung Glasfaser Portal</strong> der
              GELSENWASSER AG. Wir sind das Team blau-grün. Als Partner von
              Städten und Kommunen bieten wir Lösungen für alle Aufgaben und
              Herausforderungen der Infrastruktur.
            </p>
            <Formik
              initialValues={emptyLogin}
              validationSchema={KundenLoginSchema}
              onSubmit={handleLogin}
            >
              {({ handleSubmit }) => {
                return (
                  <FormikForm onSubmit={handleSubmit}>
                    <FormikControl
                      name="auftragsnummer"
                      label=""
                      autoComplete="off"
                      placeholder="Auftragsnummer"
                    />
                    <FormikControl
                      name="kundennummer"
                      label=""
                      autoComplete="off"
                      placeholder="Kundennummer"
                    />
                    <Button type="submit">Anmelden</Button>
                  </FormikForm>
                );
              }}
            </Formik>
          </Jumbotron>
        </Col>
      </Row>
    </>
  );
};

export default Anmeldung;
